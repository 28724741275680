<!-- Localized -->
<template>
	<svg
			xmlns="http://www.w3.org/2000/svg"
			class="h-6 w-6"
			:class="{
				'text-disabled': disabled,
			}"
			fill="none"
			viewBox="0 0 24 24"
			stroke="currentColor"
			v-on="$listeners"
			v-bind="$attrs"
	>
			<path
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
			/>
	</svg>
</template>

<script>
export default {
	props: {
		disabled: {
			type: Boolean,
			default: false,
		}
	}
};
</script>

<style lang="scss" scoped>
</style>