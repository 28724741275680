import { render, staticRenderFns } from "./adverse-media.html?vue&type=template&id=39c51bb4&scoped=true&external"
import script from "./adverse-media.js?vue&type=script&lang=js&external"
export * from "./adverse-media.js?vue&type=script&lang=js&external"
import style0 from "./adverse-media.scss?vue&type=style&index=0&id=39c51bb4&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39c51bb4",
  null
  
)

export default component.exports